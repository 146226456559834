import "./App.css"
import logo from "./image/logo.png"
import connetctIcon from "./image/connect-wallet-icon.png"
import boxImage from "./image/box-image.png"
import PlusIcon from "./image/plus-icon.png"
import PlusHoverIcon from "./image/plus-hover-icon.png"
import MinusIcon from "./image/minus-icon.png"
import MinusHoverIcon from "./image/minus-hover-icon.png"
import DiscordIcon from "./image/icon-discord.png"
import OpenSeaIcon from "./image/icon-opensea.png"
import WalletConnectIcon from "./image/walletconnectlogo.png"
import metamask from "./image/metamaskWallet.png"
import TwitterIcon from "./image/twitter-icon.png"
import { getEllipsisTxt } from "./formatters"
import { formatEther } from "@ethersproject/units"
import {
  Dialog,
  Snackbar,
  Alert,
  DialogContent,
  CircularProgress,
  IconButton,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import React, { useEffect, useState } from "react"
import {
  useEthers,
  useContractFunction,
  useNotifications,
  useEtherBalance,
} from "@usedapp/core"
import { utils, BigNumber, ethers } from "ethers"
import { Contract } from "@ethersproject/contracts"
import WalletConnectProvider from "@walletconnect/web3-provider"
import { useMoralisCloudFunction } from "react-moralis"
import { styled } from "@mui/material/styles"

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(5),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}))

function App() {
  const [count, setCount] = useState(1)
  const [tokenCounter, setTokenCounter] = useState(1000)
  const { data, fetch } = useMoralisCloudFunction("nftCount")

  useEffect(() => {
    var monday = new Date()
    monday.setDate(monday.getDate() + ((1 + 7 - monday.getDay()) % 7 || 7))
    var next_monday =
      ("0" + (monday.getMonth() + 1)).slice(-2) + "/" + monday.getDate() + "/"

    const second = 1000,
      minute = second * 60,
      hour = minute * 60,
      day = hour * 24

    let today = new Date(),
      // eslint-disable-next-line no-unused-vars
      dd = String(today.getDate()).padStart(2, "0"),
      // eslint-disable-next-line no-unused-vars
      mm = String(today.getMonth() + 1).padStart(2, "0"),
      yyyy = today.getFullYear(),
      // eslint-disable-next-line no-unused-vars
      nextYear = yyyy,
      // eslint-disable-next-line no-unused-vars
      dayMonth = "08/22/",
      next_day = next_monday + yyyy

    const countDown = new Date(next_day).getTime()
    // eslint-disable-next-line no-unused-vars
    const x = setInterval(function() {
      return;
      const now = new Date().getTime(),
        distance = countDown - now

      document.getElementById("days").innerText = Math.floor(distance / day)
      document.getElementById("hours").innerText = Math.floor(
        (distance % day) / hour
      )
      document.getElementById("minutes").innerText = Math.floor(
        (distance % hour) / minute
      )
      document.getElementById("seconds").innerText = Math.floor(
        (distance % minute) / second
      )
    }, 0)
  })
  const {
    activateBrowserWallet,
    account,
    activate,
    deactivate,
    chainId,
  } = useEthers()
  const isConnected = account !== undefined
  const [transactionSuccess, settransactionSuccess] = useState(false)
  const { notifications } = useNotifications()
  const abi = [
    {
      inputs: [{ internalType: "uint256", name: "count", type: "uint256" }],
      name: "mint",
      outputs: [],
      stateMutability: "payable",
      type: "function",
    },
    {
      inputs: [],
      name: "mintPrice",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
  ]
  const nftAddress = "0x2846be9db272F35011343D8f38280640aC5cF21D"
  const nftInterface = new utils.Interface(abi)
  const contract = new Contract(nftAddress, nftInterface)
  const provider = new ethers.providers.JsonRpcProvider(
    "https://mainnet.infura.io/v3/a22b6958cc5449a6a5bc6dc4e2c26a7a"
  )
  const nftContract = new ethers.Contract(nftAddress, abi, provider)
  const { state, send } = useContractFunction(contract, "mint", {
    transactionName: "mintnfttransaction",
  })
  // const { value, error } = useCall({ contract, method: "mintPrice", args: [] })

  const [mintPrice, setMintPrice] = useState(0)
  useEffect(() => {
    async function fetchData() {
      try {
        let x = await nftContract.mintPrice()
        let y = formatEther(x)
        setMintPrice(y)
      } catch (err) {
        console.log(err)
      }
    }
    fetchData()
  }, [mintPrice])
  useEffect(() => {
    if (data) {
      setTokenCounter(1000 - data)
    }
  }, [data])
  const etherBalance = useEtherBalance(account)
  const mintNft = (x) => {
    if (mintPrice) {
      send(BigNumber.from(x), {
        value: utils.parseEther((mintPrice * x).toString()),
      })
    }
  }

  const handleMint = (count) => {
    if (!account) {
      setOpen(true)
    } else {
      mintNft(count)
    }
  }
  const handleCloseSnack = () => {
    settransactionSuccess(false)
  }
  useEffect(() => {
    if (account) {
      setOpen(false)
    }
    if (
      notifications.filter(
        (notification) =>
          notification.type === "transactionSucceed" &&
          notification.transactionName === "mintnfttransaction"
      ).length > 0
    ) {
      settransactionSuccess(true)
      fetch()
    } else {
      settransactionSuccess(false)
    }
  }, [notifications, account, transactionSuccess])
  const TokenLimit = 1000

  const [open, setOpen] = useState(false)

  const handleOpenClick = () => {
    if (!account) {
      setOpen(true)
    }
  }
  const handleClose = () => {
    setOpen(false)
  }

  async function onConnect() {
    try {
      const provider = new WalletConnectProvider({
        infuraId: "a22b6958cc5449a6a5bc6dc4e2c26a7a",
      })
      await provider.enable()
      await activate(provider)
    } catch (error) {
      console.error(error)
    }
  }
  const windowWidth = window.innerWidth
  const mobile = windowWidth < 600
  return (
    <div className='main-bg'>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={transactionSuccess}
        autoHideDuration={5000}
        onClose={handleCloseSnack}
      >
        <Alert onClose={handleCloseSnack} severity='success'>
          You have Successfully Minted your NFT.
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={tokenCounter === 0}
        autoHideDuration={5000}
        onClose={handleCloseSnack}
      >
        <Alert onClose={handleCloseSnack} severity='info'>
          Minting Closed for this Week
        </Alert>
      </Snackbar>
      {chainId === 1 && etherBalance && mintPrice && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={formatEther(etherBalance) < count * mintPrice}
          autoHideDuration={5000}
          onClose={handleCloseSnack}
        >
          <Alert onClose={handleCloseSnack} severity='error'>
            You have insufficient funds in your wallet to mint
          </Alert>
        </Snackbar>
      )}
      {account && chainId !== 1 && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={chainId !== 1}
          autoHideDuration={5000}
          onClose={handleCloseSnack}
        >
          <Alert onClose={handleCloseSnack} severity='info'>
            Please Connect to the Mainnet network
          </Alert>
        </Snackbar>
      )}
      <div className='row align-items-center justify-content-center justify-content-lg-between'>
        <div className='col-sm-6 col-lg-4 px-lg-4 text-center text-sm-start'>
          <img src={logo} alt='' className='logo ms-lg-3' />
        </div>
        <div className='col-sm-auto p-4 pe-md-5 text-lg-end pb-md-2 ps-md-4 overflow-hidden'>
          <div className='connect-wallet-btn'>
            <button
              className=' d-sm-flex align-items-end'
              onClick={isConnected ? deactivate : handleOpenClick}
            >
              {isConnected ? (
                <span
                  style={{
                    fontWeight: "500",
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "1em",
                    wordBreak: "break-all",
                    lineHeight: "29px",
                  }}
                >
                  DISCONNECT <br />{" "}
                  {mobile ? `${getEllipsisTxt(account, 6)}` : `${account}`}
                </span>
              ) : (
                <>
                  <img
                    src={connetctIcon}
                    alt='connetct-icon'
                    className='d-none d-sm-inline-block'
                  />
                  <span>
                    CONNECT <br className='d-none d-md-inline-block' /> WALLET
                  </span>
                </>
              )}
            </button>
            <BootstrapDialog
              open={open}
              onClose={handleClose}
              aria-labelledby='parent-modal-title'
              aria-describedby='parent-modal-description'
              sx={{ zIndex: 10000 }}
            >
              <DialogContent dividers>
                <h1>Connect your wallet</h1>
                <IconButton
                  aria-label='close'
                  onClick={() => handleClose()}
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </DialogContent>
              <DialogContent dividers>
                <div>
                  <button
                    onClick={activateBrowserWallet}
                    style={{
                      border: "none",
                      width: "100%",
                      display: "flex",
                      flexDirection: "flex-start",
                      alignItems: "center",
                      background: "none",
                    }}
                  >
                    <img
                      src={metamask}
                      alt='metamask'
                      style={{
                        height: "50px",
                        width: "50px",
                        marginRight: "5%",
                      }}
                    />
                    METAMASK
                  </button>
                </div>
              </DialogContent>
              <DialogContent dividers>
                <div>
                  <button
                    onClick={onConnect}
                    style={{
                      border: "none",
                      width: "100%",
                      display: "flex",
                      flexDirection: "flex-start",
                      alignItems: "center",
                      background: "none",
                    }}
                  >
                    <img
                      src={WalletConnectIcon}
                      alt='walletconnect'
                      style={{
                        height: "50px",
                        width: "50px",
                        marginRight: "5%",
                      }}
                    />
                    WALLET CONNECT
                  </button>
                </div>
              </DialogContent>
            </BootstrapDialog>
          </div>
        </div>
      </div>
      <div className='row justify-content-center'>
        <div className='col-lg-12 mt-3 mt-sm-4 mt-lg-3 px-0 px-md-3'>
          <h3 className='heading-text'>
            <span class='d-md-inline-block'>Floor price increase soon</span> <br />
            less than 1000 NFT's left
            {/* {data && `${tokenCounter}/${TokenLimit} NFT's LEFT`} */}
          </h3>
          {/*<h3 className="heading-text d-md-none"><span id="days">7</span> DAYS - <span id="hours">8</span> : <span id="minutes">10</span> : <span id="seconds">15</span> <br />14/100 NFT's LEFT</h3>*/}
        </div>
      </div>
      <div className='row justify-content-center mt-4 mt-sm-5 mt-lg-4'>
        <div className='col-md-7 col-xl-6 order-last order-md-first pe-md-5'>
          <div className='row'>
            <div className='col-4 col-md-5'>
              <div className='price-btn'>
                <button>
                  <span>AMOUNT</span>
                </button>
              </div>
            </div>
            <div className='col-8 col-md-7 ps-0 ps-md-3'>
              <div className='number'>
                <div className='minus'>
                  <button
                    onClick={() => {
                      if (count > 1) setCount(count - 1)
                    }}
                  >
                    <span>
                      <img src={MinusIcon} alt='' className='minus-icon' />
                      <img
                        src={MinusHoverIcon}
                        alt=''
                        className='minus-hover-icon'
                      />
                    </span>
                  </button>
                </div>
                <div className='number-input mx-2 mx-md-4'>
                  <span>
                    <input
                      type='text'
                      name='text'
                      value={count > tokenCounter ? tokenCounter : count}
                      onChange={(e) => setCount(e.target.value)}
                    />
                  </span>
                </div>
                <div className='plus'>
                  <button
                    onClick={() => {
                      if (count > tokenCounter) {
                        setCount(tokenCounter)
                      } else setCount(count + 1)
                    }}
                  >
                    <span>
                      <img src={PlusIcon} alt='' className='plus-icon' />
                      <img
                        src={PlusHoverIcon}
                        alt=''
                        className='plus-hover-icon'
                      />
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div className='col-4 col-md-5 second-one'>
              <div className='price-btn'>
                <button>
                  <span>PRICE</span>
                </button>
              </div>
            </div>
            <div className='col-8 col-md-7 ps-0 ps-md-3 second-one'>
              <div className='number'>
                <div className='number-input'>
                  <span>
                    <input
                      type='text'
                      className='text-center'
                      name='text'
                      value={mintPrice && `${(1 * mintPrice).toFixed(2)} ETH`}
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className='col-4 col-md-5'>
              <div className='price-btn'>
                <button>
                  <span>TOTAL</span>
                </button>
              </div>
            </div>
            <div className='col-8 col-md-7 ps-0 ps-md-3'>
              <div className='number'>
                <div className='number-input'>
                  <span>
                    <input
                      type='text'
                      className='text-center'
                      name='text'
                      value={
                        mintPrice && count < tokenCounter
                          ? `${(count * mintPrice).toFixed(2)} ETH`
                          : `${(tokenCounter * mintPrice).toFixed(2)} ETH`
                      }
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className='col-12 mt-md-4'>
              <div className='price-btn mint-btn w-100 mt-3'>
                <button
                  className='w-100'
                  style={{ maxWidth: "100%" }}
                  disabled={state.status === "Mining" || tokenCounter === 0}
                  onClick={(e) => handleMint(count)}
                >
                  {" "}
                  {state.status === "Mining" ? (
                    <CircularProgress size={26} />
                  ) : (
                    <span>MINT NOW</span>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className='col-md-5 text-center text-md-start'>
          <img
            src={boxImage}
            style={{ maxWidth: "100%" }}
            alt=''
            className='box-image position-relative'
          />
        </div>
      </div>
      <div className='row align-items-sm-center align-items-md-end mt-sm-4 mt-lg-0'>
        <div className='col-md-6 col-lg-8 order-last order-sm-first'>
          <p className='about-text'>
            2023@ INTERGALACTIC COCKROACH . ALL RIGHTS RESERVED.
          </p>
        </div>
        <div className='col-md-6 col-lg-4 text-sm-end'>
          <div className='community'>
            <h6 className='m-0'>JOIN OUR COMMUNITY</h6>
            <div>
              <a
                href='https://opensea.io/collection/intergalactic-cockroach'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img src={OpenSeaIcon} alt='' className='social-icon' />
              </a>
              <a
                href='https://discord.gg/bJrWycyxpa'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img src={DiscordIcon} alt='' className='social-icon' />
              </a>
              <a
                href='https://twitter.com/icc_vision'
                target='_blank'
                rel='noopener noreferrer'
              >
                <img src={TwitterIcon} alt='' className='social-icon' />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default App
