import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"
import { Mainnet, DAppProvider, Config } from "@usedapp/core"
import { MoralisProvider } from "react-moralis"

const config: Config = {
  readOnlyChainId: Mainnet.chainId,
  readOnlyUrls: {
    [Mainnet.chainId]:
      "https://mainnet.infura.io/v3/45e9ad85485b465997b84e3dea8d98d5",
  },
  notifications: {
    expirationPeriod: 10000,
    checkInterval: 1000,
  },
}

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <DAppProvider config={config}>
    <MoralisProvider
      appId='001'
      serverUrl='https://moralis.icc.vision/server'
    >
      <App />
    </MoralisProvider>
  </DAppProvider>
)
//       serverUrl='https://rksaplwavk2h.grandmoralis.com:2053/server'
//  serverUrl="https://rksaplwavk2h.usemoralis.com:2053/server"

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
